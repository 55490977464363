import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";

import { PlanViewerLayerDetails } from "../planviewer";
import { IPvLayerMap, OlLayerTypeImplT, PvLayer } from "./pvlayer";
import { PvLayerWFS } from "./wfs";

export const IsLayerDKK = (
  layer: PlanViewerLayerDetails
): layer is PvLayerDKK => {
  return layer.type === "dkk";
};
export class PvLayerDKK extends PvLayer implements IPvLayerMap {
  getOlLayer(_options: Record<string, unknown>): OlLayerTypeImplT {
    const wfs_url =
      "https://service.pdok.nl/kadaster/kadastralekaart/wfs/v5_0";
    const wfs_layer_name = "kadastralekaart:Perceel";
    const style = new Style({ stroke: new Stroke({ color: "red" }) });
    const ol = PvLayerWFS.wrap(this, wfs_url, wfs_layer_name, {
      style,
      minZoom: 16,
    });
    ol.setVisible(this.show_layer || this.consultable);
    return ol;
  }
}
